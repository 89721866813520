<template>
  <div class="shipments-page">
    <h2 class="mb-4">{{$t('Delivery Orders')}}</h2>
    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Received')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
         :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td> 

          <shipblu-td class="order-4" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">
              {{ common.splitYearOfDate(data[indextr].created) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].customerInfo">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">
              {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].packages.length }}
            </p>
          </shipblu-td>
          <shipblu-td class="order-8" :data="data[indextr].cashOnDelivery">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Amount')}}</p>
            <p class="sm:text-base text-sm">
              {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].pickupDate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].pickup_date? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].deliveredOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Delivered')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      common,
      type: 'Add',
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      shipmentURL: 'api/v1/delivery-orders/',
      selected: [],
      shipments: [],
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadWarehouseShipments(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadWarehouseShipments()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadWarehouseShipments (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?current_warehouse=${this.$route.params.warehouseID}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `${this.shipmentURL}${query}`, 'get', null, true, 
        (response) => {
          this.shipments = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadWarehouseShipments()
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCursorPagination
  },
  created () {
    this.loadWarehouseShipments()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
